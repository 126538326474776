<template>

	<div>
		<div class="block-header">
		</div>

		<div class="container-fluid">
			<div class="row clearfix">
				<div class="col-lg-12">
					<div class="card">
						<div class="body">

							<div>
								<el-button  v-if="operation!=='查看'" type="primary" size="medium" style="float: right;" @click="submit">
									{{operation}}
								</el-button>
								<el-page-header @back="go(`/project-signing-list/${page}`)" :content="'签约项目--'+operation">
								</el-page-header>
							</div>

							<el-form ref="form" label-width="160px" size="mini">

								<el-form-item label="填报单位">
									<el-row>
										<el-col :span="10">
											<el-tag type="success">{{form.填报单位}}</el-tag>
										</el-col>
									</el-row>
								</el-form-item>

								<el-row>
									<el-col :span="10">
										<el-form-item label="项目名称" required>
											<el-input v-model="form.项目名称"></el-input>
										</el-form-item>
									</el-col>

									<el-col :span="10">
										<el-form-item label="建设内容" required>
											<el-input type="textarea" :rows="4" v-model="form.建设内容"></el-input>
										</el-form-item>
									</el-col>

								</el-row>


								<el-row>
									<el-col :span="10">
										<el-form-item label="本地投资方" >
											<el-input v-model="form.本地投资方"></el-input>
										</el-form-item>
									</el-col>

									<el-col :span="10">
										<el-form-item label="外来投资方" required>
											<el-input v-model="form.外来投资方"></el-input>
										</el-form-item>
									</el-col>

								</el-row>

								<el-row>
									<el-col :span="10">
										<el-form-item label="总投资额(万元)" required>
											<el-input-number :min="0" v-model="form.总投资额"></el-input-number>
										</el-form-item>
									</el-col>

									<el-col :span="10">
										<el-form-item label="一期投资额(万元)">
											<el-input-number :min="0" v-model="form.一期投资额"></el-input-number>
										</el-form-item>
									</el-col>

								</el-row>
								
								<el-row>
								
									<el-col :span="10">
										<el-form-item label="行业类别" required>
								
											<el-select v-model="form.行业类别" placeholder="请选择">
												<el-option label="农,林,牧,渔业" value="农,林,牧,渔业"></el-option>
												<el-option label="采矿业" value="采矿业"></el-option>
												<el-option label="制造业" value="制造业"></el-option>
												<el-option label="电力、燃气及水的生产和供应业" value="电力、燃气及水的生产和供应业"></el-option>
												<el-option label="建筑业" value="建筑业"></el-option>
												<el-option label="交通运输、仓储和邮政业" value="交通运输、仓储和邮政业"></el-option>
												<el-option label="信息传输、计算机服务和软件业" value="信息传输、计算机服务和软件业"></el-option>
												<el-option label="批发和零售业" value="批发和零售业"></el-option>
												<el-option label="住宿和餐饮业" value="住宿和餐饮业"></el-option>
												<el-option label="金融业" value="金融业"></el-option>
												<el-option label="房地产业" value="房地产业"></el-option>
												<el-option label="租赁和商务服务业" value="租赁和商务服务业"></el-option>
												<el-option label="科学研究、技术服务和地质勘查业" value="科学研究、技术服务和地质勘查业"></el-option>
												<el-option label="水利、环境和公共设施管理业" value="水利、环境和公共设施管理业"></el-option>
												<el-option label="居民服务和其他服务业" value="居民服务和其他服务业"></el-option>
												<el-option label="教育" value="教育"></el-option>
												<el-option label="卫生、社会保障和社会福利业" value="卫生、社会保障和社会福利业"></el-option>
												<el-option label="文化、体育和娱乐业" value="文化、体育和娱乐业"></el-option>
											</el-select>
								
										</el-form-item>
									</el-col>
								
									<el-col :span="10">
										<el-form-item label="产业类别" required>
											<el-select v-model="form.产业类别">
												<el-option label="工业" value="工业"></el-option>
												<el-option label="农业" value="农业"></el-option>
												<el-option label="服务业" value="服务业"></el-option>
											</el-select>
										</el-form-item>
									</el-col>
								</el-row>
								
								<el-row>
									
									<el-col :span="10">
										<el-form-item label="签约时间" required>
											<el-date-picker v-model="form.签约时间" align="left" type="date"
												placeholder="选择日期" :picker-options="pickerOptions" format="yyyy-MM-dd"
												value-format="yyyy-MM-dd">
											</el-date-picker>
										</el-form-item>
									</el-col>
								</el-row>
								
								<el-row>
									<el-col :span="24">
										<el-form-item label="合同文本附件" required>
											<el-tag effect="plain" type="danger" style="margin-bottom: 5px;">上传正式合同 图片/附件
											</el-tag>
											<multiple-file-upload v-model="form.合同文本附件">
											</multiple-file-upload>
										</el-form-item>
									</el-col>
								</el-row>

								<el-row>
									<el-col :span="10">
										<el-form-item label="建设地点(注明园区)" required>
											<el-input v-model="form.建设地点"></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="10">
										<el-form-item label="用地面积" required>
											<el-input  v-model="form.用地面积"></el-input>
										</el-form-item>
									</el-col>
								</el-row>

								<el-row>
									<el-col :span="10">
										<el-form-item label="投资方所属地" required>
											<place-select v-model="form.投资方所属地"></place-select>
										</el-form-item>
									</el-col>
								</el-row>
								
								<el-form-item v-if="operation!=='审批' && operation!=='填报'" label="审批信息">
									<el-form-item>
										{{form.审批状态+" "+	(form.审批意见?form.审批意见:'')}}
									</el-form-item>
								</el-form-item>

								<el-row v-if="operation==='审批'">
									<el-col :span="18">
										<el-form-item label="审批">
											<el-radio-group v-model="form.审批状态">
												<el-radio-button label="待审批"></el-radio-button>
												<el-radio-button label="审批通过"></el-radio-button>
												<el-radio-button label="审批未通过"></el-radio-button>
											</el-radio-group>
										</el-form-item>
									</el-col>
								</el-row>

								<el-row v-if="operation==='审批'">
									<el-col :span="18">
										<el-form-item label="审批意见">
											<el-input v-model="form.审批意见" :autosize="{ minRows: 2, maxRows: 4}"
												type="textarea"></el-input>
										</el-form-item>
									</el-col>
								</el-row>

							</el-form>


						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import PlaceSelect from '../components/PlaceSelect.vue'
	import projectSigningController from "../controller/projectSigningController.js"
	import MultipleFileUpload from '../components/MultipleFileUpload.vue';
	import {projectSigningValidate} from '@/assets/js/check.js'
	export default {
		components: {
			MultipleFileUpload,PlaceSelect
		},
		data() {
			return {
				operation: '',
				id: '',
				isApproval: false,
				form: {
					合同文本附件: [],
				},
        page:1,
				user: {},
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() > Date.now();
					},
					shortcuts: [{
						text: '今天',
						onClick(picker) {
							picker.$emit('pick', new Date());
						}
					}, {
						text: '昨天',
						onClick(picker) {
							const date = new Date();
							date.setTime(date.getTime() - 3600 * 1000 * 24);
							picker.$emit('pick', date);
						}
					}, {
						text: '一周前',
						onClick(picker) {
							const date = new Date();
							date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', date);
						}
					}]
				},
			}
		},
		created() {
			this.id = this.getParam('id');
			this.isApproval = this.getParam('approval') === 'approval'
			this.user = JSON.parse(sessionStorage.getItem('user'))
      this.page = this.getParam('page');


			if (this.isApproval) {
				this.operation = '审批'
				this.initForm();
			} else if (this.id) {
				this.operation = '修改'
				this.initForm();
			} else {
				this.operation = '填报'
				this.form.填报单位 = this.user.dept_name
			}

		},
		methods: {
			initForm() {
				projectSigningController.get({
					unique: this.id
				}).then(res => {
					this.form = res.data;
					if(this.form.modified.status<0){
						this.operation = '查看';
					}
				})
			},
			submit() {
				if (!projectSigningValidate(this.form,this))
					return;

				let obj = this.deepClone(this.form)

				if (this.isApproval) {
					projectSigningController.approval(obj).then(res => {
						this.go(`/project-signing-list/${this.page}`)
					})
				} else if (obj.id) {
					projectSigningController.put(obj).then(res => {
						this.go(`/project-signing-list/${this.page}`)
					})
				} else {
					projectSigningController.post(obj).then(res => {
						this.go(`/project-signing-list/${this.page}`)
					})
				}

			},
		}

	}
</script>

<style scoped>
	.el-page-header {
		margin-bottom: 40px;
	}

	.el-date-picker {
		cursor: pointer;
	}
</style>
